export * from './src/plans/PlanConfig'
export * from './src/plans/model'
        import * as me from '@peachy/product-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/juliancook/git/peachy/peachy-mono-repo/comp-kit/product/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/product-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    