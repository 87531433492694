import {
    Address,
    Company,
    MrAccount,
    MrLife,
    MrPlan,
    MrPlanBenefit,
    MrPolicy,
    MrSubscriptionQuoteResponse,
    PaymentDetails,
    User,
} from '@peachy/core-domain-pure'
import {Draft, mapById, values} from '@peachy/utility-kit-pure'
import {isBefore, startOfToday, subYears} from 'date-fns'
import {createStore, reconcile, SetStoreFunction, Store} from 'solid-js/store'
import {mapToPlanBenefit} from '../plan-model-mapper'
import {validatePolicy} from '../../../service/validation/ValidationService'
import {createSubscriptionRequest} from '../create-subscription-requests'
import {SubscriptionQuoteRepository} from '../../../service/subscription/SubscriptionQuoteRepository'
import {PlanConfig} from '@peachy/product-client'

/**
 * Stores and manages all the details relating to the SubscriptionRequest object
 */
export class SubscriptionQuoteStore {
    private subscriptionRequest: Store<Draft<MrSubscriptionQuoteResponse>>
    private storeSubscriptionRequest: SetStoreFunction<Draft<MrSubscriptionQuoteResponse>>

    constructor(private readonly repository: SubscriptionQuoteRepository, private readonly planConfig: PlanConfig) {
        [this.subscriptionRequest, this.storeSubscriptionRequest] = createStore<Draft<MrSubscriptionQuoteResponse>>(repository.get() ?? createSubscriptionRequest())
    }

    public getSubscriptionRequest() {
        return this.subscriptionRequest
    }

    public getPolicies() {
        return this.subscriptionRequest.policies
    }

    public getSubscriptionStartDate() {
        return this.subscriptionRequest.subscription.startDate
    }

    ///// user details

    public updateUserName(firstName: string, lastName: string) {
        const fullName = `${firstName} ${lastName}`
        this.storeSubscriptionRequest('account', 'user', 'firstname', firstName)
        this.storeSubscriptionRequest('account', 'user', 'lastname', lastName)
        this.storeSubscriptionRequest('account', 'contactName', fullName)
        this.storeSubscriptionRequest('subscription', 'contactName', fullName)
        this.save()
    }

    public updateCompany(company: Company) {
        this.storeSubscriptionRequest('account', 'company', company)
        this.storeSubscriptionRequest('account', 'name', company?.name)
        this.storeSubscriptionRequest('subscription', 'name', company?.name)
        this.save()
    }

    public updateEmail(email: string) {
        this.storeSubscriptionRequest('account', 'user', 'email', email)
        this.storeSubscriptionRequest('account', 'contactEmail', email)
        this.storeSubscriptionRequest('subscription', 'contactEmail', email)
        this.save()
    }

    public updateUserCognitoId(cognitoUserId: string) {
        this.storeSubscriptionRequest('account', 'user', 'cognitoUserId', cognitoUserId)
        this.save()
    }

    public updateMarketingAccepted(marketingAccepted: boolean) {
        this.storeSubscriptionRequest('account', 'user', 'marketingAccepted', marketingAccepted)
        this.save()
    }

    public updatePoliciesAccepted(policiesAccepted: boolean) {
        this.storeSubscriptionRequest('account', 'user', 'policiesAccepted', policiesAccepted)
        this.save()
    }

    public getUser(): User {
        return this.subscriptionRequest.account.user as User
    }

    public getAccount(): Draft<MrAccount> {
        return this.subscriptionRequest.account
    }

    public getCompany(): Draft<Company> {
        return this.subscriptionRequest.account.company
    }

    ///// policies

    public addPolicy(newPolicy: Draft<MrPolicy>) {
        this.storeSubscriptionRequest('policies', (prev) => [...prev, newPolicy])
        this.save()
    }

    public updateLife(policyIndex: number, lifeId: string, fields: Draft<MrLife>) {
        this.storeSubscriptionRequest('policies', policyIndex, 'lives', lifeId, fields)
        this.save()
    }

    public removeLife(policyIndex: number, lifeId: string) {
        this.updateLife(policyIndex, lifeId, undefined)
    }

    public removePolicy(removeIndex: number) {
        this.storeSubscriptionRequest('policies', (polices) => polices.filter((v, index) => removeIndex != index))
        this.save()
    }

    public isValidPolicies = () => this.getPolicies().every(policy => validatePolicy(this.getSubscriptionStartDate(), policy))

    public getPolicyForLife = (lifeId: string): Draft<MrPolicy> => {
        return this.subscriptionRequest.policies.find(p => !!p.lives[lifeId])
    }

    public getPolicyIndexForLife = (lifeId: string): number => {
        return this.subscriptionRequest.policies.findIndex(p => !!p.lives[lifeId])
    }

    public getLives() {
        return this.subscriptionRequest.policies.flatMap(p => values(p.lives))
    }

    public getLivesForPlan(planId?: string) {
        return this.getLives().filter(l => l.planId === planId)
    }

    public hasSomeLifeForPlan(planId?: string) {
        return this.getLives().some(life => life.planId === planId)
    }

    public getOldestDateOfBirthForLives(youngestAge: number, filter: (life: Draft<MrLife>) => boolean) {
        return this.getLives().filter(filter).reduce((oldestDate, life) => {
            const birthDate = new Date(life.dateOfBirth)

            return (isBefore(birthDate, oldestDate) ? birthDate : oldestDate)
        }, subYears(startOfToday(), youngestAge))
    }

    public updateStartDate(startDate: number) {
        this.storeSubscriptionRequest('subscription', 'startDate', startDate)
        this.subscriptionRequest.policies.forEach((p, i) => {
            this.storeSubscriptionRequest('policies', i, 'startDate', startDate)
        })
        this.save()
    }

    public getPlans() {
        return this.subscriptionRequest.plans as MrPlan[]
    }

    ///// benefits

    // benefitId that is passed in is the group benefit e.g. DENTAL_OPTICAL
    public addBenefit(planId: string, benefitId: string) {
        if (this.hasBenefit(planId, benefitId)) {
            return
        }
        const plan = this.getPlanById(planId)
        const benefitToAdd = this.planConfig.getBenefitForPlan(plan.configId, benefitId)
        const newBenefits = mapToPlanBenefit(benefitToAdd)
        const updatedBenefits = [...values(plan.benefits), ...newBenefits]
        this.updatePlanBenefits(planId, updatedBenefits)
        this.save()
    }

    // benefitId that is passed in is the group benefit e.g. DENTAL_OPTICAL
    public removeBenefit(planId: string, benefitId: string) {
        const plan = this.getPlanById(planId)
        const benefitTypes = this.planConfig.getBenefitTypesForPlan(plan.configId, benefitId)
        const remainingBenefits = values(plan.benefits).filter(benefit => !benefitTypes.includes(benefit.id))
        this.updatePlanBenefits(planId, remainingBenefits)
        this.save()
    }

    public updateBenefitLimit(planId: string, benefitId: string, limit: number) {
        const plan = this.getPlanById(planId)
        const benefitTypes = this.planConfig.getBenefitTypesForPlan(plan.configId, benefitId)

        const updatedBenefits = values(plan.benefits).map(benefit => ({
            ...benefit,
            limit: (benefitTypes.includes(benefit.id)) ? limit : benefit.limit
        }))

        this.updatePlanBenefits(planId, updatedBenefits)
        this.save()
    }

    public getBenefitLimit(planId: string, benefitId: string): number {
        return this.getBenefit(planId, benefitId).limit
    }

    public getBenefit(planId: string, benefitId: string): MrPlanBenefit | undefined {
        const plan = this.getPlanById(planId)
        const benefitTypes = this.planConfig.getBenefitTypesForPlan(plan.configId, benefitId)
        return values(plan.benefits).find(planBenefit => planBenefit.id == benefitTypes[0])

    }

    public getExcess(planId: string): number | undefined {
        const plan = this.getPlanById(planId)
        return plan.excess?.amountInPence
    }

    public updatePlanExcess(planId: string, excess: number) {
        this.storeSubscriptionRequest('plans', this.getPlanIndex(planId), 'excess', 'amountInPence', excess)

        const plan = this.getPlanById(planId)

        values(plan.benefits).forEach(benefit => {
            if (plan.excess?.benefitTypes?.includes(benefit.id)) {
                this.storeSubscriptionRequest('plans', this.getPlanIndex(planId), 'benefits', benefit.id, 'excess', excess)
            }
        })

        // ------------------------
        this.save()
    }



    ///// payment

    public updatePayment(paymentDetails: Draft<PaymentDetails>) {
        this.storeSubscriptionRequest('subscription', 'paymentDetails', paymentDetails)
    }

    public updateBillingAddress(hasSeparateAddress: boolean, address: Draft<Address>) {
        this.storeSubscriptionRequest('subscription', 'paymentDetails', 'billingAddress', address)
        this.storeSubscriptionRequest('subscription', 'paymentDetails', 'hasSeparateBillingAddress', hasSeparateAddress)
    }

    public getPaymentDetails() {
        return this.subscriptionRequest.subscription.paymentDetails
    }

    private getPlanIndex(planId: string) {
        return this.subscriptionRequest.plans.findIndex(p => p.id === planId)
    }

    private getPlanById(planId: string) {
        return this.getPlans().find(plan => plan.id === planId)
    }

    private hasBenefit(planId: string, benefitId: string) {
        const benefit = this.getBenefit(planId, benefitId)
        return Boolean(benefit)
    }

    private updatePlanBenefits(planId: string, benefits: MrPlanBenefit[]) {
        const mappedBenefits = mapById(benefits)
        this.storeSubscriptionRequest('plans', this.getPlanIndex(planId), 'benefits', reconcile(mappedBenefits))
    }

    private save() {
        this.repository.save(this.getSubscriptionRequest())
    }
}
