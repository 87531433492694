import { CrossIcon, ExcessIcon } from '@peachy/assets'
import { BenefitIcon, createToggleSignal, Toggle } from '@peachy/client-kit'
import { Life, MrPlan, MrPlanBenefit } from '@peachy/core-domain-pure'
import { Benefit, Excess, PlanConfig } from '@peachy/product-client'
import { classList, currencyFromPence, values } from '@peachy/utility-kit-pure'
import { micromark } from 'micromark'
import { Component, createMemo, createSignal, For, ParentComponent, Show } from 'solid-js'
import { MoreInfo } from '../Modal/MoreInfo'
import { Limit } from './Limit/Limit'
import styles from './PlanConfiguration.module.css'


export type PlanConfigurationProps = {
    planConfig: PlanConfig,
    plans: MrPlan[],
    lives: Life[],
    onAddBenefit?: (planId: string, benefitId: string) => void,
    onRemoveBenefit?: (planId: string, benefitId: string) => void,
    onUpdateBenefitLimit?: (planId: string, benefitId: string, value: number) => void,
    onUpdateExcess?: (planId: string, value: number) => void,
    isPlanEditable?: (planId: string) => boolean
}

// store for this component and it's children
export type PlanConfigurationStore = PlanConfigurationProps & {
    isPlanActive: (planId: string) => boolean
}

const [store, setStore] = createSignal<PlanConfigurationStore>()
//end store

const Title: ParentComponent = (props) => <h6 class={styles.title}>{props.children}</h6>

const MoreInfoIcon = () => <i class="icon-info"></i>

const ExcludeBenefitIcon = () => <CrossIcon class={styles.crossIcon}/>

//benefit card components
const BenefitOption: Component<{ title: string, benefitId: string, onClick?: () => void }> = (props) => (
    <div class={styles.label} onClick={props.onClick}>
        <BenefitIcon benefitId={props.benefitId} class={styles.icon}/>
        <span class={styles.name}>{props.title}</span>
        <span class={styles.moreInfo}><MoreInfoIcon/></span>
    </div>
)

const BenefitOptionModal: Component<{ benefitOption: Benefit }> = (props) => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <>
            <BenefitOption title={props.benefitOption.name} benefitId={props.benefitOption.id} onClick={toggleIsOpen}/>
            <MoreInfo isOpen={isOpen()} onDismiss={toggleIsOpen}>
                <h3>{props.benefitOption.name}</h3>
                <section innerHTML={micromark(props.benefitOption.moreInfo)}/>
            </MoreInfo>
        </>
    )
}

const BenefitOptions: Component<{ benefitOptions: Benefit[] }> = (props) => {
    return (
        <div class={classList(styles.card, styles.labelCard)}>
            <Title>Benefit</Title>
            <For each={props.benefitOptions}>
                {(option) => <BenefitOptionModal benefitOption={option}/>}
            </For>
        </div>
    )
}

const ExcessOption: Component = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <div class={classList(styles.card, styles.labelCard)}>
            <div class={styles.label} onClick={toggleIsOpen}>
                <span><ExcessIcon class={styles.icon}/></span>
                <span class={styles.name}>{'EXCESS'}</span>
                <span class={styles.moreInfo}><MoreInfoIcon/></span>
            </div>
            <MoreInfo isOpen={isOpen()} onDismiss={toggleIsOpen}>
                <h3>EXCESS</h3>
                <section
                    innerHTML={micromark("You can choose to add an excess to your employees' plans. The overall excess applies to:\n- Mental Health, Consultations & Diagnostics and Hospital Care benefits\n- Each individual on cover per year")}/>
            </MoreInfo>
        </div>
    )
}


//plan card components

//FIXME: copied from SubscriptionQuoteStore.ts - will replace this in subsequent PR (DEV-705)
export function getBenefit(plan: MrPlan, benefitId: string): MrPlanBenefit | undefined {
    const benefitTypes = store().planConfig.getBenefitTypesForPlan(plan.configId, benefitId)
    return values(plan.benefits).find(planBenefit => planBenefit.id == benefitTypes[0])
}


const Boolean: Component<BenefitValueProps> = (props) => {
    const planBenefit = getBenefit(props.plan, props.benefitConfig.id)

    const [state, setState] = createSignal(planBenefit !== undefined)

    if (props.benefitConfig.isEditable) {

        const setToggle = (newState: boolean) => {
            setState(newState)
            if (newState) {
                store().onAddBenefit?.(props.plan.id, props.benefitConfig.id)
            } else {
                store().onRemoveBenefit?.(props.plan.id, props.benefitConfig.id)
            }
        }

        return (
            <Show when={props.isEditable} fallback={<>{state() ? 'Included' : 'Not included'}</>}>
                <Toggle isOn={state()} onToggle={setToggle} class={styles.toggle} />
            </Show>
        )
    }

    return <>{props.benefitConfig.include ? 'Included' : <ExcludeBenefitIcon/>}</>
}

const FixedAnnualLimit:  Component<BenefitValueProps> = (props) => {
    const limitExists = !!props.benefitConfig.defaultLimit
    const isNumber = typeof props.benefitConfig.defaultLimit === 'number'

    return <Show when={limitExists && isNumber}>
        <p class={styles.optionalBenefitLimit}>Annual limit {currencyFromPence(props.benefitConfig.defaultLimit as number)}</p>
    </Show>
}

export type BenefitValueProps = {
    benefitConfig: Benefit,
    plan: MrPlan,
    isEditable: boolean
}

export type ExcessValueProps = {
    excessConfig: Excess,
    plan: MrPlan,
    isEditable: boolean
}

const BenefitValue: Component<BenefitValueProps> = (props) => {
    const onUpdate = (value: string) => {
        store().onUpdateBenefitLimit?.(props.plan.id, props.benefitConfig.id, parseInt(value))
    }

    const value = getBenefit(props.plan, props.benefitConfig.id)?.limit?.toString()
    const values = props.benefitConfig.values?.map(value => ({
        label: currencyFromPence(value),
        value: value.toString()
    }))

    return (
        <div class={styles.planBenefitValue}>
            <Show when={props.benefitConfig.isSupported} fallback={<ExcludeBenefitIcon/>}>
                <Show when={props.benefitConfig.type === 'boolean'}>
                    <Boolean {...props} />
                    <FixedAnnualLimit {...props}/>
                </Show>
                <Show when={props.benefitConfig.type === 'select'}>
                    <Limit
                        label="Annual limit"
                        value={value}
                        values={values}
                        onUpdate={onUpdate}
                        isEditable={props.isEditable && props.benefitConfig.isEditable}
                    />
                </Show>
            </Show>
        </div>
    )
}

const ExcessValue: Component<ExcessValueProps> = (props) => {
    const onUpdate = (value: string) => {
        store().onUpdateExcess?.(props.plan.id, parseInt(value))
    }

    const value = props.plan.excess?.amountInPence?.toString()

    const values = props.excessConfig?.values?.map(value => ({
        label: currencyFromPence(value),
        value: value.toString()
    }))

    return (
        <Show when={props.excessConfig} fallback={<div class={styles.transparent}/>}>
            <div class={styles.planExcessValue}>
                <Limit
                    value={value}
                    values={values}
                    onUpdate={onUpdate}
                    isEditable={props.isEditable}
                />
            </div>
        </Show>
    )
}

export const PlanCard: ParentComponent<{ planId: string }> = (props) => {
    const activeCss = createMemo(() => store().isPlanActive(props.planId) ? (store().isPlanEditable(props.planId) ? styles.activeEdit : styles.activeView) : '')

    return (
        <div class={classList(styles.card, styles.planCard, activeCss())}>
            {props.children}
        </div>
    )
}

const Plan: Component<{ plan: MrPlan, editable: boolean }> = (props) => {
    const planConfig = store().planConfig.getPlan(props.plan.configId)
    return (
        <PlanCard planId={props.plan.id}>
            <Title>{props.plan.name}</Title>
            <For each={planConfig.benefits}>
                {(benefitConfig) => <BenefitValue plan={props.plan} benefitConfig={benefitConfig} isEditable={props.editable} />}
            </For>
        </PlanCard>
    )
}

const PlanExcess: Component<{ plan: MrPlan, editable: boolean }> = (props) => {
    const planConfig = store().planConfig.getPlan(props.plan.configId)

    return (
        <PlanCard planId={props.plan.id}>
            <ExcessValue plan={props.plan} excessConfig={planConfig.excess} isEditable={props.editable} />
        </PlanCard>
    )
}


export const PlanConfiguration: Component<PlanConfigurationProps> = (props) => {
    setStore({
        ...props,
        isPlanActive: (planId: string) => props.lives.some(l => l.planId === planId),
        isPlanEditable: (planId: string) => props.isPlanEditable?.(planId) ?? false
    })

    return (
        <div class={styles.PlanConfiguration}>
            {/* Plan cards */}
            <div class={styles.cardContainer}>
                <BenefitOptions benefitOptions={store().planConfig.getAllBenefits()}/>
                <For each={props.plans}>
                    {(plan) => <Plan plan={plan} editable={store().isPlanEditable(plan.id)}/>}
                </For>
            </div>

            {/* Excess */}
            <div id="excessOption" class={styles.cardContainer}>
                <ExcessOption/>
                <For each={props.plans}>
                    {(plan) => <PlanExcess plan={plan} editable={store().isPlanEditable(plan.id)}/>}
                </For>
            </div>

        </div>
    )
}
